/* Icomoon */
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?2xuip9");
  src: url("../fonts/icomoon.eot?2xuip9#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.woff2?2xuip9") format("woff2"),
    url("../fonts/icomoon.ttf?2xuip9") format("truetype"),
    url("../fonts/icomoon.woff?2xuip9") format("woff"),
    url("../fonts/icomoon.svg?2xuip9#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before {
  content: "\e90d";
}
.icon-mail:before {
  content: "\e90f";
}
.icon-insta-reels:before {
  content: "\e90e";
}
.icon-insta-gallery:before {
  content: "\e90c";
}
.icon-insta-video:before {
  content: "\e90b";
}
.icon-checkmark:before {
  content: "\e90a";
}
.icon-checkmark-applied:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e908";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-arrow-down:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-instagram:before {
  content: "\e901";
}
.icon-linkedin:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\e903";
}
.icon-twitter:before {
  content: "\e904";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: ac(72px, 32px);
  line-height: 110%;
}

h1,
h2 {
  text-transform: uppercase;
}

p,
span {
  font-family: var(--font-second);
}

p,
a {
  font-weight: 300;
}

p {
  font-size: 15px;
  line-height: 200%;
}

a {
  font-size: 18px;
  color: var(--black);
  display: inline-block;
  line-height: 1.5;
}

.section__title {
  font-size: rc(24px, 21px);
  padding-bottom: 6px;
  position: relative;
  width: fit-content;

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 100vw;
    background: var(--black);
    right: 100vw;
    bottom: 0;
    transition: right 1s ease;
  }

  &.white {
    color: var(--white);

    &::before {
      background: var(--white);
    }
  }

  &.aos-animate {
    &::before {
      right: 0;
    }
  }
}

.section__title_lg {
  font-size: rc(42px, 20px);
}

.section__title_md {
  font-size: 24px;
}

.section__title_sm {
  font-size: 20px;
}

.section__link {
  text-transform: uppercase;
  display: block;
  width: fit-content;

  @mixin hover-link var(--black);

  &.white {
    &:before {
      background: var(--white);
    }
  }
}

.content-element {
  * {
    font-family: var(--font-second);
  }

  a,
  p {
    font-size: 16px;
    font-weight: 300;
  }

  a {
    text-decoration: underline;
  }

  p {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }

  ul,
  ol {
    padding-left: 15px;
    margin: 20px 0;

    li {
      font-size: 16px;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }
}

.content-element {
  h1 {
    font-size: 72px;
  }

  h2 {
    font-size: 62px;
  }

  h3 {
    font-size: 52px;
  }

  h4 {
    font-size: 42px;
  }

  h5 {
    font-size: 32px;
  }

  h6 {
    font-size: 22px;
  }
}

.article-content__text.content-element {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }
}
