.candidate-first-section {
  background-image: url("../images/candidate-main-img.jpg");
}

.benefits {
  background: var(--black);
  color: var(--white);
  padding: rc(48px, 32px) 0 rc(94px, 16px);

  .section__title {
    margin-bottom: 51px;

    &::before {
      background-color: var(--white);
    }
  }

  &__title {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 33px;
  }
}

.benefits__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;

  @mixin tab-sm {
    flex-direction: column;
  }
}

.benefits__content {
  max-width: 625px;
  margin-right: 40px;

  @mixin tab-sm {
    margin: 0 0 32px;
  }
}

.benefits__list {
  list-style: disc;
}

.benefits__item {
  margin-left: 20px;

  p {
    line-height: 150%;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.benefits__title {
  font-weight: 400;
}

.benefits__img {
  max-width: 290px;
  width: 100%;
  position: relative;
  @mixin aspect-ratio 290, 362;

  @mixin mob-lg {
    max-width: 100%;
  }
}

.benefits__bg-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(0, 0, 0, 0.4);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
