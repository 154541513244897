.article-section {
  padding: 132px 0 56px;

  .arrow-btn {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 40px;

    .arrow-btn__icon {
      margin-right: 8px;
    }

    &.bottom {
      margin: 32px 0 0;
      display: none;
      float: left;

      @mixin tab-sm {
        display: flex;
      }
    }
  }

  .article-section__title {
    margin-bottom: 24px;
  }
}

.article-content {
  &__wrap {
    @mixin media 1150 {
      flex-direction: column;
      align-items: flex-start;

      .content-element {
        max-width: 100%;
      }
    }

    @mixin media 901 {
    }
  }

  &__inner {
    img {
      height: 100%;
      float: right;
      margin-left: 75px;

      @mixin media 1150 {
        margin: 0 0 24px;
        float: none;
      }
    }

    @mixin media 1280 {
      .content-element__img {
        height: 100%;
      }
    }
  }

  &__preview {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: 100%;
    max-width: 448px;
    margin-right: 58px;
    float: left;
    margin-bottom: 70px;

    @mixin media 1150 {
      margin: 0 0 24px;
      float: none;
    }
  }

  &__img {
    width: 100%;
    max-width: 448px;
    max-height: 448px;
    height: 100%;
    flex-shrink: 0;

    @mixin aspect-ratio 1, 1;
  }

  .content-element {
    img {
      float: right;
      margin: 40px 0 40px 40px;
      object-fit: cover;

      &.even {
        float: left;
        margin: 40px 40px 40px 0;
      }

      @mixin media 1150 {
        margin: 0 0 24px;
        float: none;

        &:nth-of-type(even) {
          margin: 0 0 24px;
          float: none;
        }
      }
    }
  }

  .news__heading {
    width: fit-content;
    text-transform: uppercase;
    align-self: flex-end;
    margin-bottom: 24px;
    font-size: 16px;

    @mixin media 1150 {
      align-self: flex-start;
    }

    @mixin media 480 {
      flex-direction: column;
      align-items: flex-start;

      .split {
        transform: rotate(90deg);
      }
    }
  }
}
