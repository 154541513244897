.academy-trains {
  padding-bottom: ac(82px, 56px);
}

.academy-trains__text-with-video {
  .text-with-video__title {
    font-size: ac(24px, 18px);

    @mixin media 769 {
      margin-bottom: 34px;

      text-transform: unset;
    }
  }

  .text-with-video__text {
    @mixin media 769 {
      margin-bottom: 81px;

      &:not(.is-open) {
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        display: block;
        overflow: unset;
        padding: unset;
      }
    }
  }

  .text-with-video__arrow-btn {
    @mixin media 769 {
      display: block;
    }
  }
}

.section.quotes {
  background: var(--light-grey);
}

.quotes__section-title {
  z-index: 1;

  @mixin min-media 1640 {
    margin-bottom: 95px;
  }
}

.quotes__blocks-wrap {
  overflow: hidden;

  display: flex;
  margin: 0 auto;

  @mixin media 769 {
    flex-direction: column;
  }
}

.quotes__left-block {
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  width: 50%;

  padding: ac(56px, 36px) 36px 36px ac(56px, 36px);
  background-color: var(--light-grey);

  @mixin media 769 {
    width: 100%;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    display: block;

    width: 186px;
    height: 139px;

    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../images/quote-decor.svg);
  }

  &::before {
    top: ac(53px, 10px);
    left: -10px;
    transform: rotate(180deg);
  }

  &::after {
    bottom: 0;
    right: -10px;
  }
}

.quotes__slider-wrap {
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: ac(95px, 30px) 0;

  @mixin min-height 1440 {
    margin: auto 0;
  }
}

.quotes__slider-btn {
  font-size: 26px;

  transition: transform 250ms ease;

  &.prev {
    margin-right: 20px;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.quotes__slider {
  overflow: hidden;

  max-width: ac(560px, 369px, 1440, 1920);

  margin-right: 20px;

  .swiper-slide {
    height: auto;
  }
}

.quotes__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.quotes__item-text {
  margin-bottom: 10px;
}

.quotes__item-author {
  font-style: italic;
  font-weight: 500;
  margin-bottom: 10px;
}

.quotes__slider-pagination {
  z-index: 1;

  margin-top: auto;

  text-align: center;

  .swiper-pagination-bullet {
    opacity: 1;

    width: 10px;
    height: 10px;

    margin: 0 8px !important;
  }
}

.quotes__right-block {
  width: 50%;

  @mixin media 769 {
    width: 100%;
  }
}

.quotes__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.academy-structure {
  position: relative;

  padding: 57px 0 72px;

  background-image: url(../images/academy-structure-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  color: var(--white);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    background-color: var(--black-overlay);
  }
}

.academy-structure__title {
  margin-bottom: 32px;
}

.academy-structure__main-content {
  max-width: ac(1640px, 1038px, 1440, 1920);

  margin: 0 auto;
}

.academy-structure__descr {
  margin-bottom: 30px;

  font-family: var(--font-second);
  font-size: 16px;
  font-weight: 500;
}

.academy-structure__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px 32px;

  @mixin media 769 {
    grid-template-columns: 1fr;
  }
}

.academy-structure__item-title {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
}

.academy-structure__item-subtitle {
  font-family: var(--font-second);
  font-size: 16px;
  font-weight: 400;
}

.academy-structure__item-descr {
  font-family: var(--font-second);
  font-size: 16px;
  font-weight: 300;

  ul,
  ol {
    padding-left: 15px;
    margin: 20px 0;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }
}
