.insights-first-section {
  background: var(--black);

  .first-section-inner__content-wrap {
    border-bottom: 5px solid var(--white);
  }
}

.five-minutes {
  padding-top: 56px;
  overflow: visible;

  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
  }

  &__member {
    height: 100%;
  }

  &__desc {
    display: grid;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    grid-template-columns: 240px minmax(400px, 620px);
    grid-column-gap: 32px;

    .five-minutes__member {
      width: 100%;
      height: fit-content;
      max-width: 240px;

      grid-row: 1 / 3;

      @mixin aspect-ratio 240, 250;
    }

    .subtitle {
      font-weight: 300;
      font-size: 24px;
    }

    .section__title_md {
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    @mixin tab-sm {
      grid-template-columns: 1fr;
      grid-row-gap: 24px;
      justify-items: center;

      .five-minutes__member {
        grid-row: 2;
      }
    }
  }

  .btn-hide {
    max-width: 330px;
    width: 100%;
    margin: 58px 20px 24px;
  }

  .article-block {
    background: var(--white);
    transition: max-height 0.3s ease-in-out, opacity 0.5s ease-in-out;
    position: relative;
    max-width: 1254px;
    margin: 0 auto;

    max-height: 0;
    overflow: hidden;
    opacity: 0;

    &.open {
      overflow: visible;
      margin-bottom: 20px;
      opacity: 1;
    }

    * {
      color: var(--black);
    }

    &__inner {
      display: flex;
      height: 100%;
      padding: rc(56px, 16px);

      @mixin tab {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__preview {
      max-width: 381px;
      height: fit-content;
      width: 100%;
      position: sticky;
      top: 56px;

      margin-right: 72px;
      flex: 0 0 auto;

      display: flex;
      flex-direction: column;

      @mixin tab {
        position: static;
        max-width: 100%;
        margin: 0 0 24px;
      }

      &.youtube {
        .article-block__quote {
          margin-top: 0;
        }

        .text-with-video__video {
          margin: 0 auto;

          @mixin media 1025 {
            margin: 50px auto 0;
          }

          @mixin media 551 {
            margin: 0 auto;
          }
        }
      }
    }

    &__img {
      width: 100%;
      max-width: ac(381px, 311px, 375, 768);

      @mixin aspect-ratio 381, 363;
    }

    &__quote {
      max-width: 338px;
      padding: 25px 20px 10px;
      background: var(--white);

      position: relative;
      margin-top: -85px;
      align-self: flex-end;

      h3 {
        font-size: ac(24px, 18px);
      }

      @mixin tab {
        max-width: 100%;
        margin-left: 32px;
      }
    }

    /* Text content */
    &__content {
      max-width: 620px;

      &.content-element {
        p {
          color: var(--dark-grey);
        }
      }

      @mixin tab {
        max-width: 100%;
        padding: 0 rc(56px, 16px);
      }
    }

    .close-btn {
      right: 32px;
      top: 32px;

      @mixin mob-lg {
        display: none;
      }
    }

    .close-text {
      font-size: 16px;
      text-transform: uppercase;
      text-decoration: underline;
      text-align: center;
      margin-top: 16px;
      display: none;

      @mixin mob-lg {
        display: block;
      }
    }
  }
}

/* News section */
.news-section {
  padding: 56px 0;

  .section__title {
    margin-bottom: 47px;
  }

  .news-section__form {
    display: flex;
    justify-content: center;

    width: 89% !important;

    margin: 0 auto 30px;

    @mixin media 501 {
      flex-direction: column;
    }
  }

  .news-section__input {
    width: 350px;
    margin-right: 20px;

    @mixin media 501 {
      width: 100%;

      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .section__link {
    margin: 0 auto;
  }

  @mixin mob-lg {
    .cont:last-child {
      width: 100% !important;
    }
  }
}

.news-list {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 50px;
  max-width: 1254px;
  flex-wrap: wrap;

  &__item {
    max-width: ac(365px, 300px, 1024, 1366);
    width: 100%;
    cursor: pointer;

    display: flex;
    flex-direction: column;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  @mixin tab {
    flex-direction: column;

    &__item {
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}

.news__preview {
  width: 100%;
  @mixin aspect-ratio 365, 354;

  img {
    transition: all 0.3s ease;
  }

  @mixin tab {
    max-width: ac(500px, 311px, 375, 1024);
  }
}

.news__excerpt {
  max-width: 320px;
  min-height: 150px;
  padding: 16px;

  position: relative;
  background: var(--white);
  margin-top: ac(-62px, -95px);
  align-self: flex-end;

  p {
    @mixin max-line-length 7;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @mixin tab {
    align-self: flex-start;
    margin-left: ac(44px, 64px, 375, 1024);
    max-width: 100%;
    min-height: 1px;
    padding-bottom: 40px;
  }
}

.news__heading {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;

  .split {
    margin: 0 8px;
  }
}

.news__date {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 24px;
  display: block;
  text-transform: uppercase;
}

.news__title {
  font-size: 18px;
  margin-bottom: 10px;
}

.archived-btn {
  transition: all 0.3s ease-in-out;

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}

/* Feed social section */
.feed-socials {
  background: var(--light-grey);
  padding: ac(56px, 32px) 0 ac(72px, 40px);

  .section__title {
    margin-bottom: 48px;
  }

  .insta-slider {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    width: 100%;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 20%;
      right: -20px;
      top: 0;
      z-index: 20;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(245, 245, 245, 0.9559174011401436) 100%
      );
    }
  }

  .swiper-wrapper {
    grid-row-gap: 16px;
    align-items: end;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
    justify-content: start;
  }

  .swiper-slide {
    overflow: hidden;
    max-width: 365px;
    size: ac(365px, 252px);
    position: relative;
    margin-top: 0 !important;

    img,
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      object-position: center;
    }

    &:hover {
      .insta-slider__img {
        transform: scale(1.05);
      }
    }
  }

  .insta-slider__img {
    transition: transform 250ms ease;
  }

  .insta-slider__icon {
    i {
      position: absolute;
      color: var(--white);
      font-size: 14px;
      right: 15px;
      top: 15px;
      pointer-events: none;
    }
  }

  .swiper-button-next {
    z-index: 30;
    &:after {
      font-size: 30px;
    }
  }
}

/* Charity section */
.charity-section {
  padding: 72px 0;
  background: url("../images/charity-bg.jpg") no-repeat center / cover;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.1);
  }

  .section__title {
    margin-bottom: 80px;
  }
}

.charities-list {
  display: flex;
  justify-content: space-between;
  margin-left: -10px;

  &__item {
    max-width: 365px;
    width: 100%;
    padding: 24px;
    display: grid;
    cursor: pointer;

    margin: 0 10px;

    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    transition: background 0.3s ease;

    h3 {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    p {
      line-height: 140%;
    }

    .section__link {
      font-size: 16px;
      align-self: end;
      justify-self: end;
    }

    &:hover {
      background: var(--white);
    }
  }

  @mixin media 1150 {
    flex-direction: column;

    &__item {
      max-width: 100%;
      margin: 0;
      &:not(:last-child) {
        margin: 0 0 16px;
      }
    }
  }
}

.five-minutes__btns-wrap {
  position: relative;

  display: flex;
  justify-content: center;

  @mixin media 651 {
    flex-direction: column;
    align-items: center;

    .five-minutes__btn {
      margin: 30px auto;
    }
    .five-minutes__btn-archived {
      margin: 0 auto 30px;
    }
  }

  .five-minutes__btn-archived {
    max-width: 190px;

    span {
      font-family: var(--font-main);
    }
  }
}

.five-minutes__btn {
  span {
    font-family: var(--font-main);
  }
}

.archive-block.article-block {
  background-color: var(--black);
  * {
    color: var(--white);
  }

  .archive-block__item {
    .article-block * {
      color: var(--black);
    }
  }
}

.archive-block__swiper {
  position: relative;
  overflow: hidden;

  padding: 0 40px;
}

.archive-block__swiper-nav {
  position: absolute;
  top: 150px;
  left: 0;
  z-index: 1;

  transform: translateY(-50%);

  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  transition: setTransition(top);

  .archive-block__swiper-btn {
    font-size: 24px;

    transition: setTransition(transform);

    &:hover {
      transform: scale(1.2);
    }
  }
}
