html {
  font-size: 16px;

  @mixin media 1450 {
    font-size: 14px;
  }

  @mixin media 1025 {
    font-size: 12px;
  }

  @mixin media 551 {
    font-size: 10px;
  }
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  /* overflow-x: hidden; */

  background: var(--white);
  color: var(--black);
  font-family: var(--font-main);
  /* overflow-x: hidden; */
}

.fp-watermark {
  display: none;
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
}

.page-404__content-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 90vw;
  height: 90vh;

  padding: 20px;
  border: 2px solid var(--black);

  .arrow-btn {
    .arrow-btn__icon {
      position: relative;
      top: 1px;
    }
  }
}

.page-404__title {
  margin-bottom: 15px;

  font-size: ac(56px, 32px);
  text-align: center;
}

.page-404__text {
  margin-bottom: 15px;

  text-align: center;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: ac(1640px, 1254px, 1440, 1920);
  width: perc(1254);

  @mixin desk-xl {
    width: 100% !important;
  }

  @mixin tab-md {
    width: 89% !important;
  }
}

section,
.section {
  position: relative;
  overflow: hidden;

  background-color: var(--white);
  z-index: 3;
}

.section-black {
  background: var(--black);

  * {
    color: var(--white);
  }
}

/* Background to image */
.decor-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../images/img-pattern.png") no-repeat center / cover;
  z-index: -1;
}

/* Main page */
.first-section {
  padding: rc(200px, 150px) 0 32px;
  height: 100vh;
  min-height: 670px;
  z-index: 1;
  overflow: visible;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--black-overlay);
    }
  }

  @mixin min-height 768 {
    padding: 0;

    .cont {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  @media only screen and (max-width: 551px) and (min-height: 800px) {
    .cont {
      align-items: flex-end;
    }

    .heading-section {
      transform: translate(-50%, -15%);

      h1 {
        margin-bottom: 265px;
      }
    }
  }
}

.heading-section {
  max-width: 875px;
  z-index: 5;
  position: fixed;

  &__title {
    color: var(--white);
    margin-bottom: 112px;
  }

  &__form {
    display: flex;
    align-items: center;

    .input-item {
      max-width: 520px;
      width: 100%;
      margin-right: 24px;
      overflow: hidden;
    }

    input {
      width: 100%;

      @mixin tab-sm {
        &::placeholder {
          width: 258px;

          /* For Safari */
          max-width: 258px !important;
        }
      }

      @mixin media 680 {
        &::placeholder {
          opacity: 0;
        }
      }
    }

    @mixin mob-lg {
      flex-direction: column;

      .input-item {
        margin: 0 0 40px;
      }

      .btn {
        width: 100%;
      }
    }
  }

  .hero-page-typeit {
    position: absolute;
    height: 100%;
    top: 0;
    pointer-events: none;

    width: fit-content;
    left: 305px;

    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: var(--white);
    font-family: var(--font-second);
    font-size: 20px;
    font-weight: 300;

    @mixin max-line-length-one;

    @mixin tab-sm {
      left: 260px;
      max-width: 180px;
    }

    @mixin media 680 {
      left: 0;
      max-width: 400px;
    }
  }

  @mixin max-height 670 {
    position: relative;
    width: 100% !important;

    &__title {
      margin-bottom: 75px;
    }
  }

  @mixin tab-md {
    width: 89%;
  }

  @mixin mob-lg {
    left: 50%;
    transform: translateX(-50%);
  }
}

.first-section-inner {
  height: 400px;

  @mixin media 1025 {
    height: 173px;
  }
}

.first-section-inner__content-wrap {
  position: fixed;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 400px;

  padding-top: 92px;

  @mixin media 1025 {
    height: 173px;

    padding: * 20px;

    background-image: var(--mob-bg);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: var(--black-overlay);
  }
}

.first-section-inner__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;
  object-fit: cover;

  &.desk {
    @mixin media 769 {
      display: none;
    }
  }

  &.mob {
    display: none;

    @mixin media 769 {
      display: block;
    }
  }
}

.first-section-inner__title {
  z-index: 1;

  color: var(--white);
  font-size: ac(42px, 20px, 1024);
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}

/* Quote Section */
.quote-section {
  padding: ac(56px, 20px) 0;
}

.quote-inner {
  max-width: 1038px;
  margin: 0 auto;

  font-style: italic;
  text-align: center;

  * {
    font-size: 16px;
    /* color: var(--dark-grey); */
  }

  &__text {
    font-weight: 300;
    margin-bottom: 10px;
  }

  &__subtext {
    font-weight: 200;
  }
}

.terms-section {
  padding-top: 150px;

  padding-bottom: 40px;

  &__heading {
    text-align: center;
    margin-bottom: 40px;
  }

  .terms-title,
  .terms-subtitle {
    margin-bottom: 20px;
  }

  .terms-title {
    font-size: 50px;
  }

  .terms-subtitle {
    font-size: 42px;
  }
}

.brief {
  overflow: unset;
  padding-top: 64px;

  @mixin media 768 {
    padding-top: 72px;
  }

  .brief__cont {
    @mixin media 768 {
      width: 100% !important;
    }
  }

  .news-list {
    margin-bottom: 0;
  }

  .btn-wr {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: ac(50px, 30px);
  }

  .news-list-archive-swiper {
    display: none;
  }
}

.brief__title {
  margin-bottom: ac(53px, 25px);
}

.brief__content-wrap {
  display: flex;

  @mixin media 768 {
    flex-direction: column;
  }
}

.brief__main-wrap {
  position: sticky;
  /* position: -webkit-sticky; */
  top: 0;
  overflow: hidden;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  width: 50%;
  height: 100vh;

  margin-right: 32px;

  color: var(--white);

  text-align: center;

  &:hover {
    .brief__main-photo {
      transform: scale(1.1);
    }
  }

  @mixin media 768 {
    position: relative;

    width: 100%;
    height: 620px;

    margin-right: 0;
    margin-bottom: 32px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    display: block;

    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.65));
  }
}

.brief__main-photo {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  transition: transform 250ms ease;
}

.brief__main-article-info {
  z-index: 1;

  padding-bottom: 75px;
}

.brief__main-article-tag {
  margin-bottom: 24px;

  font-family: var(--font-second);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.3em;
  text-transform: uppercase;
}

.brief__main-article-title {
  max-width: 290px;

  margin-bottom: 14px;

  font-size: 24px;
  font-weight: 600;
}

.brief__main-article-date {
  font-family: var(--font-second);
  font-size: 11px;
  font-weight: 300;
}

.brief__article-list {
  width: 50%;
  margin-top: 40px;

  @mixin media 901 {
    /* columns: 1; */
  }

  @mixin media 768 {
    width: 89% !important;

    margin: 0 auto;
  }
}

.grid-sizer {
  width: calc(50% - 16px);

  @mixin media 1025 {
    width: 100%;
  }
}

.brief__article-item {
  width: calc(50% - 16px);
  float: left;
  margin-bottom: ac(56px, 32px);

  &:first-of-type {
    margin-top: -40px;
  }

  @mixin media 1025 {
    width: 100%;

    margin-bottom: 32px;
  }

  @mixin media 768 {
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.brief__article-link {
  &:hover {
    .brief__article-img {
      transform: scale(1.1);
    }
  }
}

.brief__article-img-wrap {
  overflow: hidden;

  margin-bottom: 14px;

  @mixin media 768 {
    height: 260px;
  }
}

.brief__article-img {
  width: 100%;
  height: auto;

  transition: transform ease 250ms;
}

.brief__article-tag {
  margin-bottom: 8px;

  font-family: var(--font-second);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.brief__article-title {
  margin-bottom: 9px;

  font-size: 20px;
  font-weight: 600;
}

.brief__article-descr {
  font-family: var(--font-second);
  font-weight: 200;
  font-size: 15px;
}

.brief__article-date {
  font-family: var(--font-second);
  font-weight: 300;
  font-size: 11px;
}

.leading {
  padding: ac(55px, 32px) 0 ac(82px, 56px);
}

.text-with-video,
.train__content-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1254px;
  margin: 0 auto;

  @mixin media 769 {
    flex-direction: column;
  }
}
.text-with-video-buttons{
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-end;
  .arrow-btn{
    width: fit-content;
    text-align: right;
    font-weight: inherit;
  }
}

.text-with-video__text-block,
.train__text-block {
  width: 50%;

  margin-right: 20px;

  @mixin media 769 {
    width: 100%;

    margin-right: 0;
    margin-bottom: 32px;

    &.is-open {
      .text-with-video__text {
        max-height: 1000px;
      }

      .text-with-video__arrow-btn {
        .arrow-btn__icon {
          transform: rotate(180deg);
        }

        &:hover {
          .arrow-btn__icon {
            transform: rotate(180deg) !important;
          }
        }
      }
    }

    &:not(.is-open) {
      .text-with-video__text {
        @mixin max-line-length 6;
      }
    }
  }
}

.text-with-video__title {
  margin-bottom: 8px;

  font-size: 24px;
  font-weight: 600;

  @mixin media 769 {
    margin-bottom: 48px;
  }
}

.text-with-video__text {
  overflow: auto;

  max-height: 320px;

  margin-bottom: 35px;

  font-family: var(--font-second);
  font-size: 15px;
  font-weight: 200;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--black);
  }

  p {
    font-family: var(--font-second);
    font-size: 15px;
    font-weight: 200;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @mixin media 769 {
    margin-bottom: 43px;
  }
}

.text-with-video__text-candidate {
  overflow: auto !important;
  margin-bottom: 20px !important;
}

.btn.academy-trains-btn {
  max-width: 360px;

  @mixin media 415 {
    display: block;
    margin-top: 15px;
    font-size: 15px;
    padding: 8px 30px;
  }
}

.text-with-video__arrow-btn {
  margin-left: auto;

  &.mob {
    display: none;

    &:hover {
      .arrow-btn__icon {
        transform: none !important;
      }
    }
  }

  @mixin media 768 {
    &.desktop {
      display: none;
    }

    &.mob {
      display: block;
    }
  }
}

.text-with-video__video-block,
.train__video-block {
  width: 50%;

  @mixin media 769 {
    width: 100%;
  }
}

.text-with-video__video,
.train__video {
  position: relative;

  width: 100%;
  max-width: 480px;

  margin-left: auto;

  @mixin media 769 {
    margin: 0 auto;
  }

  .text-with-video__video-preview,
  .train__video-preview {
    @mixin aspect-ratio 480, 330;

    width: 100%;
  }

  .text-with-video__video-play-btn,
  .train__video-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 116px;
    height: 116px;

    transition: transform 250ms ease;

    @mixin media 769 {
      width: 91px;
      height: 91px;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    path {
      fill: rgba(255, 255, 255, 0.7);

      transition: fill 250ms ease;
    }

    &:hover {
      transform: translate(-50%, -50%) scale(1.03);

      path {
        fill: rgba(255, 255, 255, 1);
      }
    }
  }

  .decor-bg {
    left: 30px;
    top: 30px;

    @mixin media 769 {
      left: 15px;
      top: 15px;
    }
  }
}

.statistics {
  padding: ac(67px, 40px) 0 ac(91px, 26px);

  color: var(--white);

  /* background-image: url(../images/statistics-section-bg.jpg); */
  /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
  background-color: transparent;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: block;

    background-color: var(--black-overlay);
  }

  .cont {
    position: relative;
    z-index: 1;
  }
}

.statistics__bg-img {
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &.active {
    display: block;
  }
}

.statistics__title {
  margin-bottom: 82px;
}

.statistic-bubble {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  margin-bottom: 69px;
  margin-top: -20px;
  margin-left: -20px;
}

.statistic-bubble__item {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  width: 211px;
  height: 211px;
  margin-top: 20px;
  margin-left: 20px;

  padding: 48px 28px 0;
  border-radius: 50%;

  color: var(--black);
  background-color: var(--white);
}

.statistic-bubble__num {
  margin-bottom: 15px;

  font-family: var(--font-main);
  font-size: 42px;
  font-weight: 600;
  line-height: 1;

  .count-up {
    font-family: var(--font-main);
  }
}

.statistic-bubble__text {
  max-height: 72px;
  width: 100%;
  font-family: var(--font-second);
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.statistics__subtitle {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.single-quote-section__quote-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* margin-bottom: ac(56px, 50px); */
  padding: ac(56px, 24px) 0;

  font-family: var(--font-second);
}

.single-quote-section__quote {
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
  margin-bottom: 10px;

  @mixin media 769 {
    font-size: 12px;
    /* color: #737373; */
  }
}

.single-quote-section__quote-author {
  font-size: 16px;
  font-weight: 200;

  @mixin media 769 {
    font-size: 12px;
    font-weight: 300;
    color: #737373;
  }
}

#bold-credits {
  display: block;

  /* max-width: 280px; */
  margin-top: 30px;
  max-width: 150px;

  svg {
  }

  transition: setTransition(transform);

  &:hover {
    transform: scale(1.1);
  }

  @mixin mob-lg {
    /* margin: * auto; */
  }
}

.plyr.plyr--stopped .plyr__controls {
  display: none;
}

.plyr.plyr--paused .plyr__controls {
  display: none;
}

.checkBox .check-title {
  font-family: var(--font-second);
  font-weight: 200;
}

.news-list-archive-swiper {
  max-width: 1254px;
  margin: 0 auto;
  padding-bottom: ac(100px, 50px);
}

.news-list-swiper-body {
  position: relative;

  .swiper-button {
    font-size: 30px;

    &::after {
      display: none;
    }

    &-prev {
      left: 0;
      transform: translate(-150%, -50%);
    }

    &-next {
      right: 0;
      transform: translate(150%, -50%);
    }
  }

  .news-list__item {
    margin: 0 auto;
  }

  @media (max-width: 1025px) {
    margin: 0 auto;

    .swiper-button {
      &-prev {
        transform: translate(-100%, -50%);
      }

      &-next {
        transform: translatX(100%, -50%);
      }
    }

    .news-list-swiper {
      width: calc(100% - 40px);
      margin: 0 auto;
    }
  }
  @media (max-width: 550px) {
    .swiper-button {
      font-size: 25px;

      &-prev {
        transform: translateX(-50%);
      }

      &-next {
        transform: translateX(50%);
      }
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination__btn {
  transition: setTransition(transform);

  &:hover {
    transform: scale(1.1);
  }
  &.prev {
    margin-right: 20px;
  }
  &.next {
    margin-left: 20px;
  }

  &.pagination__btn-start-end {
    i {
      &:first-child {
        margin-right: -6px;
      }
    }
  }
}

.pagination__pages {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__page {
  position: relative;
  padding: 5px 10px;
  line-height: 1;

  &.active {
    &::before {
      left: 0;
      width: 100%;
    }
  }

  &:hover {
    &::before {
      left: 0;
      width: 100%;
    }
  }

  &::before {
    background: var(--black);
    bottom: 0;
    content: "";
    height: 2px;
    position: absolute;
    right: 0;
    transition: 0.25s ease-in-out;
    width: 0;
  }
}

.banners-section {
  padding: 20px 0;
}

.banners-slider {
  .swiper-slide {
    height: auto;
  }
}

.banners-slider__item {
  width: 100%;
  height: 100%;
  /* max-height: 400px; */
}

.banners-slider__item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banners-slider__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  margin-top: 20px;
}

.banners-swiper__btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  font-size: 24px;

  &.swiper-button-lock {
    display: none;
  }

  i {
    transition: transform ease 250ms;
  }

  &:hover {
    &.prev {
      i {
        transform: translateX(-5px);
      }
    }
    &.next {
      i {
        transform: translateX(5px);
      }
    }
  }
}

.insight-salary {
  padding: 30px 0;
}

.insight__inner {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ac(30px, 15px);

  @mixin media 901 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @mixin media 601 {
    grid-template-columns: minmax(0, 1fr);
  }
}

.insight-card {
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  &:hover {
    .insight-card__img {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.insight-card__img {
  overflow: hidden;

  img {
    transition: setTransition(transform);
  }
}

.popup-apply__bottom {
  font-size: 14px;

  a {
    font-size: 14px;

    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.popup-apply__bottom-text {
  margin-bottom: 20px;
}

.salary-guides-form {
  display: flex;
  gap: ac(24px, 10px);

  @mixin media 769 {
    flex-wrap: wrap;

    .btn {
      margin: 0 auto;
    }
  }
}

.report-section {
  position: relative;
  overflow: hidden;

  &__bg {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 551px) {
        object-position: 30% 50%;
      }
    }
  }

  &__title {
    margin-top: auto;
    font-size: ac(53px, 24px);
    font-weight: 600;
    line-height: normal;
    margin-bottom: ac(40px, 30px);
    color: var(--white);
    font-family: var(--font-main);

    span {
      font-weight: 300 !important;
      padding-left: ac(20px, 0px);
    }
  }

  &__wrap {
    padding: 48px 0;
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: ac(534px, 400px);
  }
}
