.client-first-section {
  /* background-image: url("../images/client-main-img.jpg"); */
}

.services {
  background: var(--white);
  padding-top: 0;
  .section__title {
    margin-bottom: 40px;
  }
}

/* Products section */
.products {
  padding: rc(48px, 32px) 0 rc(40px, 24px);

  .section__title {
    margin-bottom: rc(63px, 32px);
  }

  .products-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: ac(38px, 20px);
    transition: 0.5s ease-in-out;

    &__item {
      width: 100%;
      display: grid;
      padding: 24px;
      min-height: 215px;
      transition: all 0.3s ease;
      cursor: pointer;
      position: relative;

      * {
        color: var(--white);
      }

      &-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.55);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }

      &-title {
        font-size: 18px;
        margin-bottom: 10px;
        text-transform: uppercase;
      }

      &-excerpt {
        @mixin max-line-length 3;

        font-weight: 500;
        margin-bottom: 25px;
        height: fit-content;
      }

      object {
        place-self: end start;
        justify-self: end;
      }

      .section__link {
        font-size: 16px;
        height: 20px;
        align-self: end;
        justify-self: end;

        &:before {
          background: var(--white);
        }
      }

      &:hover {
        box-shadow: 0 0 15px var(--black);
      }

      @mixin mob-lg {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    @mixin tab {
      grid-template-columns: repeat(3, 1fr);
    }

    @mixin tab-sm {
      grid-template-columns: repeat(2, 1fr);
    }

    @mixin mob-lg {
      display: block;
      overflow: hidden;
    }
  }

  .btn-hide {
    margin: 24px auto 0;
    display: none;
    max-width: 205px;
    width: 100%;

    @mixin mob-lg {
      display: flex;
    }
  }
}

/* ERTA section */
.erta-section {
  padding: 48px 0 26px;

  .section__title {
    margin-bottom: ac(78px, 32px);
  }
}

.erta-content {
  &__wrap {
    display: flex;
    justify-content: space-between;
    max-width: 1144px;
    margin: 0 auto;

    @mixin tab-sm {
      flex-direction: column;
    }
  }

  &__img {
    max-width: 290px;
    width: 100%;
    height: 100%;
    margin-right: 40px;

    @mixin aspect-ratio 290, 290;

    @mixin tab-sm {
      margin: 0 0 32px;
    }
  }

  &__content {
    max-width: 718px;

    .content-element {
      margin-bottom: 30px;

      h3 {
        font-family: var(--font-main);
        font-size: 18px;
        text-transform: uppercase;
      }
    }

    @mixin tab-sm {
      max-width: 100%;
    }
  }
}

.values-list__item {
  @mixin media 501 {
    height: 100%;
    padding-bottom: 20px;
  }
}
