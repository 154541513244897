.btn {
  background: var(--white);
  color: var(--black);
  padding: 8px 42px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;

  position: relative;
  z-index: 2;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 110%;
    height: 200px;
    background-color: var(--black);
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: var(--white);

    &::before {
      opacity: 1;
      /*      left: 50%;*/
      transform-origin: 100px 100px;
      transform: scale(1) translate(-50%, -50%);
    }
  }

  &.black {
    border: 1px solid var(--black);
    background: var(--black);
    color: var(--white);

    &::before {
      background: var(--white);
    }

    &:hover {
      color: var(--black);
    }
  }
}

.arrow-btn {
  display: flex;
  align-items: center;

  font-size: 14px;
  text-transform: uppercase;

  &:hover {
    .arrow-btn__icon {
      transform: translateX(-5px);
    }
  }

  &.right-icon {
    .arrow-btn__icon {
      margin-right: 0;
      margin-left: 4px;
    }

    &:hover {
      .arrow-btn__icon {
        transform: translateX(5px);
      }
    }
  }
}

.arrow-btn__icon {
  display: inline-block;

  margin-right: 4px;

  font-size: 10px;

  transition: transform ease 250ms;
}

.close-btn {
  position: absolute;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(45deg);
  }

  i {
    pointer-events: none;
  }
}

.btn-hide {
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    transition: transform 0.3s ease-in-out;
    font-size: 15px;
  }

  &.open {
    i {
      transform: rotate(180deg);
    }
  }
}
