.job-search-first-section {
  background-image: url(../images/jobs-search-main-bg.jpg);
}

.jobs-search {
  padding-bottom: ac(80px, 56px);
}

.jobs-search__content-wrap {
  display: flex;

  @mixin media 769 {
    flex-direction: column;
  }
}

.jobs-search__block-title {
  margin-bottom: 43px;

  font-size: ac(24px, 18px, 1025, 1366);
  text-transform: uppercase;
}

.jobs-search__filters {
  min-width: ac(240px, 180px);

  @mixin media 769 {
    display: none;
  }
}

.jobs-search__filter-list {
  padding-bottom: 36px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.jobs-search__filter-item {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 51px;
  }
}

.jobs-search__filter-title {
  margin-bottom: 27px;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.jobs-search__filter-option-wrap {
  &:not(:last-child) {
    margin-bottom: 14px;
  }

  cursor: pointer;

  .jobs-search__filter-option-checkbox:checked + .jobs-search__filter-option {
    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }

    &:hover {
      &::after {
        transform: scaleX(1) scaleY(2);
        transform-origin: left;
      }
    }
  }
}

.jobs-search__filter-option-checkbox {
  display: none;
}

.jobs-search__filter-option {
  @mixin max-line-length-one;

  position: relative;

  width: fit-content;
  max-width: 200px;

  font-family: var(--font-second);
  font-size: 16px;
  font-weight: 300;
  text-align-last: left;
  text-transform: capitalize;

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &::after {
    content: "";
    position: absolute;

    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    background-color: var(--black);
    transform: scaleX(0);
    transform-origin: right;

    transition: transform 350ms ease;
  }
}

.jobs-search__filters-mob {
  display: none;

  margin-bottom: 20px;

  @mixin media 769 {
    display: block;
  }

  .select2 {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.jobs-search__filters-mob-title {
  position: relative;

  width: fit-content;

  margin: 0 auto 20px;
  padding: 0 20px;

  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    width: 100vw;
    height: 1px;

    background-color: rgba(0, 0, 0, 0.25);
  }

  &::before {
    right: 100%;
  }
  &::after {
    left: 100%;
  }
}

.jobs-search__filters-swiper {
  position: relative;
  overflow: hidden;

  .swiper-slide {
    width: fit-content;
  }
}

.jobs-search__filter-mob-option {
  font-family: var(--font-second);
  font-size: 16px;
  text-transform: uppercase;
}

.jobs-search__filters-swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  font-size: 14px;

  transition: opacity ease 250ms;

  &::before {
    content: "";
    position: absolute;
    top: 50%;

    transform: translateY(-50%);
    z-index: -1;

    display: block;

    width: 65px;
    height: 60px;

    pointer-events: none;
  }

  &.prev {
    left: 0;

    &::before {
      left: -1px;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0)
      );
    }
  }

  &.next {
    right: 0;

    &::before {
      right: -1px;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 80%
      );
    }
  }

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0;
  }
}

.jobs-search__results-wrap {
  width: 100%;

  margin: 0 31px;

  @mixin media 769 {
    margin: 0;
  }
}

.jobs-search__search-form {
  display: flex;

  margin-bottom: 30px;

  @mixin media 769 {
    flex-direction: column;

    .select2-container {
      margin-bottom: 32px;
    }
  }
}

.jobs-search__search-input {
  width: 100%;

  margin-right: 24px;

  @mixin media 769 {
    margin-right: 0;
    margin-bottom: 32px;

    font-weight: 300;

    &::placeholder {
      font-weight: 300;
    }
  }
}

.jobs-search__result-count {
  margin-bottom: ac(62px, 40px);

  font-family: var(--font-second);
  font-size: 16px;
  font-weight: 500;

  @mixin media 769 {
    font-size: 14px;
    text-align: center;
  }
}

.jobs-search__job-card {
  padding-bottom: 31px;

  &:not(:last-child) {
    margin-bottom: 33px;
    border-bottom: 1px solid var(--black-overlay);
  }

  &.card-invert {
    padding: 20px;
    background: var(--black);

    .jobs-search__job-card-title,
    .jobs-search__job-card-date,
    .jobs-search__job-card-short-info,
    .jobs-search__job-card-descr,
    .jobs-search__job-card-link,
    .jobs-search__job-card-apply-btn {
      color: var(--white);

      &::before {
        background: var(--white);
      }
    }
  }
}

.jobs-search__job-card-title {
  margin-bottom: 7px;

  font-size: 24px;
  font-weight: 600;
  line-height: 1;

  &:hover {
    text-decoration: underline;
  }

  @mixin media 769 {
    margin-bottom: 6px;

    line-height: 1.33;
  }
}

.jobs-search__job-card-date {
  margin-bottom: 14px;

  font-family: var(--font-second);
  font-size: 14px;
  font-weight: 200;
}

.jobs-search__job-card-short-info {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 17px;

  font-family: var(--font-main);
  font-size: 14px;
  font-weight: 300;
}

.jobs-search__job-card-location {
  margin-right: ac(35px, 28px);
}

.jobs-search__job-card-type {
  margin-right: 35px;

  text-transform: uppercase;
}

.jobs-search__job-card-descr {
  @mixin max-line-length 2;

  margin-bottom: 20px;

  color: #737373;

  font-family: var(--font-main);
  font-size: 16px;
  font-weight: 300;

  @mixin media 769 {
    @mixin max-line-length 4;
  }
}

.jobs-search__job-card-salary {
  @mixin media 769 {
    display: none;
  }
}

.jobs-search__job-card-salary-short {
  display: none;
  @mixin media 769 {
    display: block;
  }
}

.jobs-search__job-card-bottom-wrap {
  display: flex;
  justify-content: space-between;
}

.jobs-search__job-card-apply-btn {
  position: relative;

  margin: 0 !important;

  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.jobs-search__articles-list-wrap {
  min-width: 23%;

  @mixin media 1025 {
    display: none;
  }

  &.mob {
    display: none;

    @mixin media 1025 {
      display: block;
    }

    .jobs-search__block-title {
      margin-bottom: 20px;
    }

    .swiper-slide {
      width: 288px;
    }
  }
}

.jobs-search__articles-list {
  max-width: 340px;
}

.jobs-search__articles-list-item {
  a {
    width: 100%;
  }
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.jobs-search__article-text-wrap {
  position: relative;
  z-index: 1;

  width: 89.92%;
  max-height: 176px;

  padding: 12px 15px;
  margin-top: -92px;

  background-color: var(--white);
}

.jobs-search__article-company-name-date {
  margin-bottom: 17px;

  font-family: var(--font-second);
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.5;
}

.jobs-search__article-title {
  margin-bottom: 10px;

  font-size: 18px;
  font-weight: 600;
  line-height: 1.333;
}

.jobs-search__article-descr {
  color: var(--dark-grey);

  font-family: var(--font-second);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;

  @mixin max-line-length 5;
}

.jobs-search__filter-result {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.jobs-search__filter-result-item {
  display: flex;
  align-items: center;

  padding: 5px 10px;
  border: 1px solid var(--black);

  margin-right: 10px;
  margin-bottom: 10px;
}

.jobs-search__filter-result-item-remove {
  margin-left: 7px;

  transition: setTransition(color);

  &:hover {
    color: red;
  }
}
