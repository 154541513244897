.popup-site {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  display: none;
  align-items: center;
  justify-content: center;

  background: var(--black-overlay);

  &.open {
    display: flex;
  }

  .popup-site__inner {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    max-width: 90vw;
    max-height: 90vh;
    width: 746px;

    padding: 40px 56px 50px;

    background: var(--white);

    scrollbar-width: thin;
    scrollbar-color: var(--white) var(--black);

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: var(--white);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--black);
      border-right: solid 2px var(--white);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--black);
    }

    @mixin mob-xl {
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      padding: 40px 16px 40px;
    }

    img {
      max-width: 309px;
      margin: 0 0 15px 15px;
      @media (max-width: 650px) {
        float: revert !important;
        clear: both;
        margin: 0 auto 30px;
      }
    }

    .section__title {
      margin-bottom: 32px;
    }

    .close-btn {
      top: 24px;
      right: 24px;
    }
  }

  .content-element {
    h1,
    h2,
    h3 {
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 18px;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      font-family: var(--font-second);
    }

    p {
      font-size: 16px;
      line-height: 1.5;

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }

    a {
      font-size: 16px;
      font-family: var(--font-second);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.grey-text {
    .content-element {
      p {
        color: var(--dark-grey);
      }
    }
  }
}

/* Charity Pop Up */
.case-study-popup {
  .popup-site__inner {
    width: 974px;
    position: relative;
    padding: * ac(56px, 20px);

    img {
      max-width: 321px;
    }
  }

  .popup-site__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .content-element {
      max-width: 503px;
      margin-right: 32px;
    }
  }

  @mixin tab-md {
    .popup-site__inner {
      padding-top: calc(ac(275px, 150px, 375, 900) + 32px);
    }

    .case-main-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: ac(275px, 150px, 375, 900);

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: 0 35%;
      }
    }

    .close-btn {
      color: var(--white);
    }
  }
}

/* executive-search-popup */
.popup-site.executive-search-popup {
  .popup-site__inner {
    padding: 32px;
  }

  .section__title {
    padding: 0 24px;
  }

  .content-element {
    padding: 0 24px;
  }
}

.executive-search-popup__black-bottom {
  margin-top: 98px;
  padding: 30px 32px 58px;

  background-color: var(--black);
  color: var(--white);
}

.executive-search-popup__case {
  margin-bottom: 8px;

  font-family: var(--font-second);
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
}

.executive-search-popup__title {
  margin-bottom: 32px;

  font-size: 24px;
  font-weight: 300;
}

.executive-search-popup__text {
  & > * {
    font-family: var(--font-second);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.625;
  }
}

/* leading-popup */
.leading-popup {
  .leading-popup__title {
    margin-bottom: 14px;
    padding-right: 15px;

    font-size: 24px;
    font-weight: 600;
  }
}

.apply-popup {
  .btn.apply-popup__btn {
    width: fit-content;
    max-width: unset;

    @mixin media 551 {
      width: 100%;
    }
  }

  .checkBox {
    margin: 0;

    .check-title {
      cursor: auto;
      font-size: 16px;
      font-weight: 300;
      font-family: var(--font-second);

      &::before {
        display: flex;
        justify-content: center;
        cursor: pointer;

        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      a {
        @mixin hover-link var(--black);
        font-size: 16px;
        font-weight: 400;
      }
    }

    input:checked + .check-title:before {
      border-color: transparent;
      background: var(--black);
      color: var(--white);

      content: "\e90a";
    }
  }
}

.apply-popup__subtitle {
  margin-bottom: 54px;

  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
}

.apply-popup__applied-icon {
  font-size: 53px;

  color: #21d500;
}

.apply-popup__applied-subtitle {
  margin-bottom: 15px;

  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
}

.apply-popup__applied-text {
  font-family: var(--font-second);
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
}

.checkBox.elit-checkbox {
  margin: 0;

  .check-title {
    display: flex;
    align-items: center;
    cursor: auto;
    font-size: 16px;
    font-weight: 300;
    font-family: var(--font-second);
    height: 25px;

    &::before {
      top: 50%;
      transform: translateY(-50%);

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    a {
      @mixin hover-link var(--black);
      font-size: 16px;
      font-weight: 400;
    }
  }

  input:checked + .check-title:before {
    border-color: transparent;
    background: var(--black);
    color: var(--white);

    content: "\e90a";
  }
}
