/* Opportunities section */
:root {
  --x: rc(98px, 80px);
  --y: rc(24px, 32px);

  @mixin mob-sm {
    --x: 40px;
    --y: 75px;
  }
}

.opportunities-slider-wrap {
  position: relative;
  padding-left: 75px;
  padding-right: 75px;
}

.opportunities-slider {
  max-width: 100%;
  width: 100%;
  overflow: visible;

  /*.swiper-wrapper {
    clip-path: inset(0 0 0 0);
  }*/

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    height: ac(330px, 290px);
    /* old height with img */
    /* height: ac(435px, 290px); */

    @mixin tab {
      opacity: 0;
      transition: opacity 0.2s ease;
      &.swiper-slide-next,
      &.swiper-slide-active {
        opacity: 1;
      }
    }

    @mixin tab-sm {
      &.swiper-slide-next {
        opacity: 0;
      }
    }

    /* &:nth-child(2n) {
      margin-top: ac(60px, 32px);
      .opportunities-slider__info {
        left: 20%;
        right: auto;
        top: auto;
        bottom: 32px;

        @mixin mob-xl {
          left: 10%;
        }
      }
    } */

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }

  &__img {
    overflow: hidden;
    height: 100%;
    width: 60%;

    img {
      transition: 0.3s ease;
    }

    @mixin mob-xl {
      width: 80%;
    }
  }

  &__info {
    position: absolute;
    z-index: 1;
    background: var(--white);
    padding: 16px;
    /*width: ac(332px, 300px, 900, 1366);*/
    transition: all 1s ease-in-out;
    right: 0;
    top: 32px;
    width: 80%;

    @mixin mob-xl {
      width: 90%;
    }

    /*@mixin tab-md {
      width: ac(400px, 290px, 375, 900);
    }*/
  }

  &__name {
    text-transform: uppercase;
    font-size: 13px;
  }

  .section__link {
    margin: 34px auto 0;
  }

  &__descr {
    @mixin max-line-length 3;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  @mixin mob-lg {
    .swiper-button-prev {
      left: 10px;
    }

    .swiper-button-next {
      right: 10px;
    }
  }
}

.opportunities {
  background: var(--white);
  padding: 32px 0;
  z-index: 20;

  &.invert-opportunities {
    background: var(--black);

    .section__title {
      color: var(--white);

      &::before {
        background-color: var(--white);
      }
    }

    .opportunities-button-next,
    .opportunities-button-prev {
      color: var(--white);
    }

    .section__link {
      color: var(--white);

      &::before {
        background-color: var(--white);
      }
    }
  }

  .section__title {
    margin-bottom: 56px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    height: ac(502px, 650px, 900, 1366);

    @mixin tab-md {
      flex-direction: column;
      height: auto;
    }
  }

  &__item {
    position: relative;
    width: 265px;
    height: rc(435px, 290px);

    .opportunities__descr {
      @mixin max-line-length 3;
    }

    .opportunities__img {
      overflow: hidden;

      img {
        transition: all 0.3s ease;
      }
    }

    &:hover {
      img {
        transform: scale(1.15);
      }
    }

    &:nth-child(1) {
      .opportunities__info {
        top: var(--y);
        left: var(--x);
        transform: translateX(50%);
      }

      .opportunities__img {
        transform: translateX(-50%);
      }
    }

    &:nth-child(2) {
      align-self: flex-end;

      .opportunities__info {
        bottom: var(--y);
        right: var(--x);
        transform: translateX(-50%);

        @mixin mob-sm {
          bottom: 20px;
        }
      }

      .opportunities__img {
        transform: translateX(50%);
      }
    }

    &:nth-child(3) {
      .opportunities__info {
        top: var(--y);
        right: var(--x);
        transform: translateX(-50%);
      }

      .opportunities__img {
        transform: translateX(50%);
      }

      img {
        object-position: left;

        @mixin tab-md {
          object-position: 50%;
        }
      }

      @mixin tab-md {
        .opportunities__info {
          left: var(--x);
          bottom: var(--y);
          right: auto;
          top: auto;

          transform: translateX(50%);
        }

        .opportunities__img {
          transform: translateX(-50%);
        }
      }

      @mixin mob-sm {
        .opportunities__info {
          bottom: 20px;
        }
      }
    }

    @mixin tab-md {
      &:not(:last-child) {
        margin-bottom: 64px;
      }
    }

    @mixin mob-lg {
      .opportunities__descr {
        @mixin max-line-length 2;
      }
    }
  }

  &__img {
    height: 100%;
    opacity: 0;
    transition: all 1s ease-in-out;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__info {
    position: absolute;
    background: var(--white);
    padding: 16px;
    opacity: 0;
    width: ac(332px, 300px, 900, 1366);
    transition: all 1s ease-in-out;

    @mixin tab-md {
      width: ac(400px, 290px, 375, 900);
    }
  }

  &__name {
    text-transform: uppercase;
    font-size: 13px;
  }

  .section__link {
    margin: 34px auto 0;
  }

  @mixin mob-lg {
    .cont.full {
      width: 100% !important;
    }
  }

  /* AOS animation */
  .opportunities__item.aos-animate {
    .opportunities__img,
    .opportunities__info {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
