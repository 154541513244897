.erta-first-section {
  background-image: url("../images/erta-main-img.jpg");
}

/* ERTA info */
.erta-info {
  &:before {
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .erta-info__bg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  padding: rc(48px, 40px) 0 rc(72px, 40px);

  .section__title {
    margin-bottom: 87px;
  }

  .content-element {
    max-width: ac(835px, 610px, 1440, 1920);

    h3 {
      font-size: 18px;
      text-transform: uppercase;
      margin: 27px 0 24px;
    }

    ul {
      list-style: disc;
      display: grid;
      grid-template-columns: repeat(2, minmax(330px, 396px));
      grid-column-gap: ac(100px, 50px, 768, 1366);

      li {
        font-size: 16px;
        text-transform: uppercase;
        margin: 0;
      }

      @mixin tab-sm {
        grid-template-columns: 1fr;
      }
    }

    @mixin tab {
      max-width: 100%;
    }
  }
}

.erta-inner__wrap {
  display: flex;
  justify-content: space-between;
  max-width: ac(1280px, 1038px, 1440, 1920);
  margin: 0 auto;
  align-items: center;

  @mixin tab {
    flex-direction: column;
    align-items: center;
  }
}

.erta-inner__img {
  margin-right: 32px;
  width: 100%;
  height: 100%;
  max-width: 286px;

  @mixin aspect-ratio 286, 286;

  &:nth-of-type(even) {
    margin: 0 0 0 32px;
    max-width: 396px;

    @mixin aspect-ratio 396, 328;

    @mixin tab {
      order: -1;
      margin: 0 0 32px;
    }
  }

  @mixin tab {
    width: 202px;
    height: 202px;
    margin: 0 0 32px;
  }
}

/* How we train */
.train {
  padding: 56px 0;

  .section__title {
    margin-bottom: 8px;
  }
}

.train__text-block {
  max-width: 610px;
}

/* Case studies */
:root {
  --arrow-pos: 80px;
}

.case-studies {
  background: var(--light-grey);
  padding: ac(24px, 40px) 0 ac(56px, 40px);

  &__prompt {
    text-align: center;
    margin-bottom: 24px;
  }
}

.case-list {
  max-width: 1038px;
  margin: 0 auto;
}

.case-list__wrap {
  display: grid;
  justify-content: center;

  &:nth-child(odd) {
    grid-template-columns: minmax(560px, 692px) minmax(330px, 346px);

    /* Case with full height */
    .case-item.full {
      grid-row: 1 / 3;
      grid-column: 2;
    }

    /* Change order with even   */
    .case-item:nth-child(even):not(.full) {
      .case-item__text {
        order: 2;
      }

      .arrow-btn {
        right: 32px;
      }

      &:before {
        transform: translateX(100%);
      }

      &:hover {
        .arrow-btn {
          right: calc(50% - 16px);
        }
      }
    }
  }

  &:nth-child(even) {
    grid-template-columns: minmax(330px, 346px) minmax(560px, 692px);

    .case-item.full {
      grid-row: 1 / 3;
      grid-column: 1;
    }

    .case-item:nth-child(odd):not(.full) {
      .case-item__text {
        order: 2;
      }

      .arrow-btn {
        right: 32px;
      }

      &:before {
        transform: translateX(100%);
      }

      &:hover {
        .arrow-btn {
          right: calc(50% - 16px);
        }
      }
    }
  }

  @mixin tab-md {
    &:nth-child(odd),
    &:nth-child(even) {
      grid-template-columns: 1fr;

      .case-item.full {
        grid-row: auto;
        grid-column: auto;
      }

      .case-item {
        &:before {
          display: none;
        }
      }

      .case-item__text {
        display: flex;
        flex-direction: column;
      }

      .case-item__text,
      .case-item.full .case-item__text {
        order: -1 !important;
        width: 100%;
        min-height: ac(200px, 150px, 375, 1366);
        position: relative;
      }

      .arrow-btn {
        position: static;
        align-self: flex-end;
        margin-top: 20px;
      }

      .case-item__preview {
        max-height: ac(300px, 150px, 375, 1366);
      }
    }
  }
}

.case-item {
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &__text {
    width: ac(296px, 240px, 900, 1366);
    min-height: 346px;
    z-index: 11;

    flex: 0 0 auto;
    padding: ac(32px, 24px) ac(32px, 16px);

    * {
      transition: all 0.3s ease-in-out;
    }
  }

  &__preview {
    height: 100%;
  }

  .arrow-btn {
    position: absolute;
    bottom: ac(32px, 16px);
    right: calc(50% + var(--arrow-pos));
    transition: all 0.3s ease-in-out;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;

    @mixin tab-md {
      object-position: 50% 40%;
    }
  }

  /* Hover element */
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;

    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(6px);
    transition: all 0.3s ease-in-out;

    transform: translateX(-100%);
  }

  &.item-white {
    &:before {
      background: rgba(255, 255, 255, 0.6);
    }
  }

  /* Hover effect */
  &:hover {
    &:before {
      transform: translate(0, 0) !important;
    }

    .arrow-btn {
      right: 32px;
    }
  }

  /* Full height */
  &.full {
    flex-direction: column;
    min-height: 296px;

    .case-item__text {
      width: 346px;
      position: relative;
    }

    .arrow-btn {
      right: 32px;
    }

    &:before {
      transform: translateY(-100%);
    }

    &:hover {
      .arrow-btn {
        bottom: -50px;
      }
    }
  }

  @mixin tab-md {
    flex-direction: column;

    &:hover {
      img {
        transform: scale(1.1);
      }

      &.item-black {
        background: var(--white);

        * {
          color: var(--black);
        }
      }

      &.item-white {
        background: var(--black);

        * {
          color: var(--white);
        }
      }
    }
  }
}

.item-black {
  background: var(--black);

  .section__title_md {
    @mixin max-line-length 3;
  }

  * {
    color: var(--white);
  }
}

.item-white {
  background: var(--white);

  .section__title_md {
    @mixin max-line-length 3;
  }

  * {
    color: var(--black);
  }
}

/* Erta section on other pages*/
.erta-section {
  &:before {
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .erts-section__bg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}
