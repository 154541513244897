.testimonials-section {
  padding: rc(48px, 32px) 0 rc(112px, 100px);

  .section__title {
    margin-bottom: ac(70px, 38px);
  }
}

.testimonials-swiper {
  max-width: ac(1038px, 560px, 768, 1366);
  overflow: hidden;
  position: static;

  &__wrap {
    position: relative;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .swiper-slide {
    max-width: 1038px;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    &:after {
      font-size: rc(35px, 28px);
    }

    @mixin tab-sm {
      top: auto;
      bottom: ac(-50px, -65px);
    }
  }

  .swiper-pagination {
    bottom: -45px;
  }
}

/* Testimonial on Erta page */

.testimonial {
  padding: rc(42px, 32px) 0 rc(82px, 40px);
  background: url("../images/testimonial-bg.jpg") no-repeat center / cover;

  .section__title {
    margin-bottom: 50px;
  }
}

.testimonial-block {
  padding: 56px 28px;
  max-width: 888px;
  margin: 0 auto;
  background: var(--white);

  &__wrap {
    display: flex;
    align-items: flex-end;

    @mixin tab-md {
      flex-direction: column;
      align-items: center;
    }
  }

  @mixin tab-md {
    padding-top: 88px;
  }
}

.testimonial-avatar {
  flex: 0 0 auto;
  padding-top: 90px;
  margin-right: 28px;
  text-align: center;

  &__img {
    width: ac(190px, 295px);
    height: ac(190px, 295px);

    border-radius: 50%;
    margin-bottom: 17px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
  }

  @mixin tab-md {
    order: 2;
    margin: 24px 0 0;
    padding: 0;
  }
}

.testimonial-content {
  position: relative;

  justify-self: self-end;
  align-self: self-start;

  max-width: 503px;

  font-family: var(--font-second);
  font-size: 16px;

  p:not(:last-child) {
    margin-bottom: 24px;
  }

  &:before {
    content: "";
    width: 70px;
    height: 53px;
    position: absolute;
    left: -100px;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='70' height='52.537' viewBox='0 0 70 52.537'%3E%3Cpath id='f' d='M45.459 50C28.44 76.935 27.7 83 27.7 86.7c0 12.727 9.175 15.835 15.391 15.835 8.584 0 15.1-5.92 15.1-14.8 0-6.364-3.4-11.1-8.731-13.023L61.442 50ZM85.713 74.715 97.7 50H81.717C64.7 76.935 63.958 83 63.958 86.7c0 12.727 9.175 15.835 15.391 15.835 8.584 0 15.1-5.92 15.1-14.8C94.444 81.374 91.04 76.639 85.713 74.715Z' transform='translate(-27.7 -50)'/%3E%3C/svg%3E");
  }

  @mixin tab-md {
    max-width: 100%;

    &:before {
      left: 0;
      top: -70px;
    }
  }
}
