/* Contact Section */
.contact-us,
.join-us {
  padding: rc(56px, 40px) 0 rc(64px, 32px);

  .cont {
    max-width: 1280px;
  }

  .section__title {
    margin-bottom: ac(60px, 32px);
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rc(55px, 32px);
    margin-right: 40px;

    width: 100%;

    textarea,
    .file-item {
      grid-column: -1 / 1;
      height: 41px;
    }

    .elit-checkbox-wrap {
      grid-column: -1 / 1;
    }

    .checkbox-item {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .input-wrap.full-w {
      grid-column: -1 / 1;
    }

    .btn {
      max-width: 167px;
    }

    .select2-selection__rendered {
      width: 96%;
      display: inline-block !important;

      @mixin max-line-length-one;
    }
  }

  &__img {
    max-width: 480px;
    width: 100%;
    position: relative;
    margin-bottom: 40px;

    .image {
      width: 100%;
      @mixin aspect-ratio 480, 330;
    }

    .decor-bg {
      left: 30px;
      top: 30px;
    }
  }

  @mixin tab {
    &__wrap {
      flex-direction: column;
    }

    &__img {
      .decor-bg {
        top: 16px;
        left: 16px;
      }
    }

    &__form {
      order: 2;
      margin: 0;

      grid-template-columns: 1fr;
      width: 100%;
      max-width: 480px;
    }
  }
}

.join-us__form {
  &.join-us__form-popup {
    margin-right: 0;
  }

  /* @mixin media 1025 {
    max-width: unset;
  } */
}
