.footer {
  background: var(--black);
  padding: rc(72px, 50px) 0 rc(57px, 53px);
  position: relative;
  z-index: 20;

  a,
  p {
    color: var(--white);
  }

  &__wrap {
    display: grid;
    grid-template-columns: minmax(200px, 260px) ac(265px, 210px, 768, 1366) 1fr;
    align-items: start;
    grid-column-gap: ac(55px, 25px, 768, 1366);
    grid-row-gap: rc(70px, 56px);
  }

  &__logo {
    max-width: ac(260px, 160px, 768, 1366);
    transition: all 0.3s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:hover {
      transform: scale(1.06);
    }

    @mixin tab {
      max-width: 100%;
    }
  }

  &__contacts {
    display: flex;
    justify-content: space-between;
    max-width: 700px;
    width: 100%;
    justify-self: end;

    a {
      font-size: 16px;
      width: fit-content;

      &:not(.contacts__location) {
        @mixin hover-link;
      }
    }

    .contacts__location {
      max-width: 228px;
    }

    @mixin tab {
      grid-column: -1 / 1;
      justify-self: start;
      max-width: 100%;
    }

    @mixin min-media 1536 {
      max-width: 100%;
    }
  }

  &__nav {
    .menu-item {
      width: fit-content;

      &:not(:last-child) {
        margin-bottom: 17px;
      }
    }

    .menu-link {
      text-transform: uppercase;
      font-size: rc(16px, 20px);

      @mixin hover-link;
    }

    @mixin tab {
      grid-column: 2 / 4;

      .menu-item {
        margin: * auto;
      }
    }

    @mixin tab-sm {
      grid-column: auto;
    }
  }

  &__credits p,
  .policy-block a {
    font-size: 14px;
  }

  .contacts {
    a:hover {
      text-decoration: none;
    }

    &__location:hover {
      text-decoration: underline !important;
    }
  }

  .policy-block {
    justify-self: end;

    a {
      &:not(:last-child) {
        margin-right: ac(42px, 20px, 1024, 1366);
      }

      @mixin hover-link;
    }
  }

  @mixin tab {
    .policy-block,
    &__credits,
    &__socials {
      align-self: flex-end;
    }

    .policy-block {
      display: flex;
      flex-direction: column;
      justify-self: center;
      a:not(:last-child) {
        margin: 0 0 24px;
      }
    }
  }

  @mixin tab-sm {
    .footer__wrap {
      grid-template-columns: repeat(2, 1fr);
    }

    .footer__logo {
      align-self: center;
      max-width: 260px;
    }

    .footer__credits {
      grid-row: 3;
    }

    .footer__contacts {
      align-items: flex-start;
    }

    .policy-block {
      justify-self: start;
    }
  }

  @mixin mob-lg {
    .footer__wrap {
      grid-template-columns: 1fr;
      margin: 0 auto;

      .menu-item {
        margin: * 0;
      }
    }

    .footer__logo {
      grid-row: 4 / 6;
    }

    .policy-block {
      grid-row: 6;
    }

    .footer__credits {
      grid-row: 7;
    }

    .footer__socials {
      justify-content: space-between;
    }

    .socials a {
      font-size: 25px;
    }
  }
}
