input,
textarea {
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--black);
  border-radius: 0;
  outline: none;
  padding-bottom: 9px;
  resize: none;

  font-family: var(--font-second);
  font-size: 20px;
  font-weight: 300;
  color: var(--grey);
  @mixin transparent-bg-input var(--grey);

  &::placeholder {
    font-family: var(--font-second);
    font-size: 20px;
    font-weight: 300;
    color: var(--grey);
  }

  &.white {
    border-color: var(--white);
    color: var(--white);

    @mixin transparent-bg-input var(--white);

    &::placeholder {
      color: var(--white);
    }
  }
}

textarea {
  min-height: 115px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--dark-grey);
  }
}

.file-item {
  position: relative;
  display: flex;
  justify-content: space-between;

  .file-btn {
    position: absolute;
    right: 0;
    bottom: 8px;
    width: 127px;
    height: 36px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--black);

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      font-size: 0;
      padding: 0;
      cursor: pointer;
    }

    label {
      color: var(--black);
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      transition: 0.3s ease;
      line-height: 1;
    }

    &:hover {
      border-color: transparent;
      label {
        color: var(--white);
      }
    }
  }

  .file-name {
    font-size: 15px;
    white-space: nowrap;
  }

  .file-text {
    width: 100%;
  }
}

.checkbox-item {
  /* .pc-label.check-title {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 7px;
      display: inline-block;
      transform: rotate(45deg);
      height: 20px;
      width: 10px;
      border-bottom: 3px solid var(--black);
      border-right: 3px solid var(--black);

      opacity: 0;

      transition: setTransition(opacity);
    }
  }

  .checkBox input:checked {
    & + .check-title::before {
      background: transparent;
    }

    & + .check-title::after {
      opacity: 1;
    }
  } */
}

.select2 {
  width: 100% !important;

  margin-right: 24px !important;
  border-bottom: 2px solid var(--black);

  .select2-selection {
    height: 100% !important;

    border: none !important;
  }

  .select2-selection__rendered {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;

    padding-bottom: 9px !important;

    color: var(--grey) !important;
    font-family: var(--font-second);
    font-size: 20px;
    font-weight: 300;
  }

  .select2-selection__placeholder {
    color: var(--grey) !important;
  }
}

.select2-results__option--highlighted.select2-results__option--selectable {
  background: var(--black) !important;
}

.input-wrap {
  position: relative;
  padding-top: 10px;
  min-width: 250px;

  .input-placeholder {
    position: absolute;
    left: 0;

    pointer-events: none;

    font-family: var(--font-second);
    font-size: 20px;
    font-weight: 300;
    color: var(--grey);

    transition: setTransition(transform);
    transform-origin: left bottom;
  }

  &:focus-within,
  &.not-empty {
    .input-placeholder {
      transform: scale(0.8) translateY(-100%);
    }
  }

  textarea,
  input {
    width: 100%;
  }
}
