.job-section {
  margin-top: 144px;
  padding-bottom: 129px;
}

.job__back-btn {
  margin-bottom: 12px;
}

.job__wrap {
  display: flex;

  padding-top: 40px;
  border-top: 1px solid var(--black-overlay);
}

.job__info-wrap {
  flex-grow: 1;

  margin-right: ac(139px, 20px);

  @mixin media 769 {
    margin-right: 0;
  }
}

.job__title {
  margin-bottom: 18px;

  font-size: 24px;
  font-weight: 600;
  text-transform: none;
}

.job__date {
  display: block;

  margin-bottom: 30px;

  color: var(--black-overlay);

  font-family: var(--font-second);
  font-size: 14px;
  font-weight: 300;
}

.job__short-info {
  display: flex;

  margin-bottom: 32px;
}

.job__short-info-text {
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 40px;
  }
}

.job__content {
  margin-bottom: 44px;

  * {
    color: var(--dark-grey);

    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
  }

  strong {
    font-weight: 600;

    * {
      font-weight: 600;
    }
  }

  &.content-element {
    h1,
    h2,
    h3 {
      color: var(--black);
      text-transform: none;

      &:last-of-type {
        margin-bottom: 24px;
      }
    }

    h1 {
      font-size: 22px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }
  }
}

.job__apply-btn {
  position: relative;

  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;

  @mixin media 551 {
    display: none;
  }
}

.job__apply-btn-mob {
  display: none;

  width: 100%;
  max-width: unset;

  &.applied {
    background: var(--white);
    font-family: var(--font-main);
    font-weight: 600;
    font-size: 18px;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    @mixin media 551 {
      display: flex;
    }
  }

  @mixin media 551 {
    display: block;
  }
}

.job__roles-list-wrap {
  /* min-width: 23%; */
  min-width: 23%;
  max-width: 288px;

  @mixin media 1024 {
    min-width: 30%;
  }

  @mixin media 769 {
    display: none;
  }
}

.job__roles-list-title {
  margin-bottom: 40px;

  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
}

.job__roles-list-item {
  max-width: 100%;
  &:not(:last-child) {
    margin-bottom: 41px;
  }
}

.job__roles-list-item-title {
  margin-bottom: 8px;

  font-size: 18px;
}

.job__roles-list-item-date {
  margin-bottom: 22px;

  color: var(--black-overlay);

  font-family: var(--font-second);
  font-size: 14px;
  font-weight: 300;
}

.job__roles-list-item-short-text {
  margin-bottom: 14px;

  font-family: var(--font-second);
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}

.job__roles-list-item-descr {
  word-wrap: break-word;
  margin-top: 24px;
  font-family: var(--font-second);
  font-size: 16px;
  font-weight: 300;
  @mixin max-line-length 4;

  color: var(--dark-grey);
}

.job__roles-list-item-view-btn {
  margin-top: 10px;
}
