/* ----------------------- Common desktop header ----------------------- */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 15px 0;
  transition: all 0.3s ease-in-out;

  display: flex;
  align-items: center;

  &.scrolled {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(6.5px);

    .logo {
      background-color: var(--black);
    }

    .menu-link {
      color: var(--black);

      &:before {
        background: var(--black);
      }
    }

    .burger span {
      background: var(--black);
    }

    .socials a {
      color: var(--black);
    }

    .socials-dropdown__title {
      color: var(--black);
    }
  }

  .cont {
    max-width: ac(1700px, 1300px, 1440, 1920);
    width: perc(1300);
  }

  .logo {
    size: 60px;
    flex-shrink: 0;
    mask-image: url("../images/logo-white.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: var(--white);
    transition: all 0.3s ease;

    &::before {
      display: none;
    }

    &:hover {
      transform: scale(1.06);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .navbar {
    display: flex;
    align-items: center;

    @mixin min-media 1640 {
      justify-content: space-between;
    }
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    display: flex;
    flex-grow: 1;

    @mixin min-media 1640 {
      flex-grow: 0;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    margin: 0 rc(100px, 50px) 0 rc(70px, 50px);

    @mixin media 1300 {
      margin: 0;
      justify-content: flex-end;
    }
  }

  .menu-item {
    position: relative;

    &:not(:last-child) {
      margin-right: 32px;
    }

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          position: absolute;
          right: -15px;
          top: 50%;
          transform: translateY(-50%);
          content: "\e906";
          font-family: "icomoon";
          font-size: ac(10px, 8px);
          transition: transform 0.3s ease;
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: ac(16px, 14px);
    color: var(--white);
    text-transform: uppercase;
    transition: all 0.3s ease;
    @mixin hover-link;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 5px 10px 5px 10px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(6.5px);
    box-sizing: content-box;
    display: none;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    li {
      .menu-link {
        padding: 2px 0;
        font-size: ac(14px, 12px);
        white-space: nowrap;
      }
    }

    @mixin media 1300 {
      &.last-child {
        left: auto;
        right: -20px;
      }
    }
  }

  .socials-dropdown__title {
    color: var(--white);
  }

  &:not(.scrolled) {
    .dropdown-menu {
      background: transparent;
      box-shadow: none;
      backdrop-filter: none;
      li {
        .menu-link {
          color: var(--white);
        }
      }
    }
  }

  &.white {
    .dropdown-menu {
      top: 75%;
      background: var(--white);
      box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
      backdrop-filter: none;
      li {
        .menu-link {
          color: var(--black);

          &:before {
            background: var(--black);
            display: block;
          }
        }
      }

      .socials-dropdown__title {
        color: var(--black);
      }
    }

    &.scrolled {
      .dropdown-menu {
        li {
          .menu-link {
            color: var(--black);
            &:before {
              background: var(--black);
            }
          }
        }
      }

      .socials-dropdown__title {
        color: var(--black);
      }
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @media (--mobile-menu-end-point) {
        padding: 5px 0;
      }

      &:hover {
        @media (--mobile-menu-end-point) {
          .dropdown-menu {
            display: block;
          }

          .menu-link.dropdown-toggle {
            &:after {
              transform: scale(-1) translateY(50%);
            }
          }
        }
      }
    }
  }

  @mixin media 1300 {
    .socials {
      display: none;
    }
  }

  @mixin mob-lg {
    &.active {
      background: #000;
    }
  }
}

/* Second White Header */

.header.white {
  background: var(--white);
  padding: 0;
  position: fixed;

  &.scrolled {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(6.5px);

    .logo {
      background-color: var(--black);
    }
  }

  a {
    color: var(--black);
  }

  .logo {
    background-color: var(--black);
  }

  .socials-dropdown__title {
    color: var(--black);
  }

  .menu {
    position: relative;

    span {
      position: absolute;
      background: var(--black);
      bottom: 0;
      height: 4px;

      z-index: -1;
      opacity: 0;

      left: var(--left-position-span);
      width: var(--width-span);
      transition: var(--span-transition);

      &.active {
        opacity: 1;
      }
    }

    &:hover {
      .menu-item {
        border-color: transparent;
      }
    }
  }

  .menu-link {
    padding: 34px 0;

    &:before {
      display: none;
    }
  }

  @mixin tab {
    background-color: transparent;
    padding: 15px 0;

    .logo {
      background-color: var(--white);
    }
  }

  @mixin mob-lg {
    &.active {
      background: #000;
    }
  }

  &.black-text {
    border-bottom: 1px solid var(--black);

    .burger,
    .menu-toggle {
      span {
        background: var(--black);
      }
    }

    &.active {
      span {
        background: var(--white);
      }
    }

    @mixin tab {
      background-color: transparent;
      padding: 15px 0;

      .logo {
        background-image: url("../images/logo-black.svg");
      }

      &.active {
        .logo {
          background-image: url("../images/logo-white.svg");
        }
      }
    }
  }
}

.header.insight-header {
  .burger span {
    background: var(--black);
  }
  @mixin tab {
    background-color: transparent;
    padding: 15px 0;

    .logo {
      background-color: var(--black);
    }
  }
}
/* end of Common desktop header */

/* ----------------------- ONLY Mobile HEADER ----------------------- */
.mobile-header {
  display: none;
  z-index: 100 !important;

  position: absolute !important;
  right: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  background: #000;
  overflow-y: scroll;

  padding: 152px 32px ac(43px, 100px);
  transition: all 0.3s ease-in-out;

  transform: translateX(100%);

  &.active {
    transform: translateX(0);
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 460px;

    .menu-item {
      border-bottom: 1px solid var(--white);
      position: relative;
      max-width: 95%;

      &:before {
        content: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      }

      &.no-opened {
        &:before {
          content: "";
        }
      }

      &.dropdown {
        & > .menu-link {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          &::after {
            display: none;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            content: "\e906";
            font-family: "icomoon";
            font-size: 12px;
            transition: transform 0.3s ease;
          }
        }

        .dropdown-toggle__icon {
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          transition: transform 0.3s ease;
          font-size: 12px;
        }
      }

      &.active {
        .menu-link.dropdown-toggle {
          &:after {
            transform: scale(-1) translateY(50%);
          }
          .dropdown-toggle__icon {
            transform: scale(-1) translateY(50%);
          }
        }
      }
    }

    .menu-link {
      color: var(--white);
      text-transform: uppercase;
      padding: 16px 0;
      width: 100%;
    }

    .dropdown-menu {
      position: static;
      top: auto;
      left: auto;
      right: auto;
      min-width: 100%;
      padding: 0;
      padding-left: 8px;
      background: transparent;
      backdrop-filter: none;
      box-sizing: content-box;
      display: block;
      box-shadow: none;
      transition: all 0.3s ease;
      overflow: hidden;
      height: 0;

      li {
        .menu-link {
          padding: 5px 0;
          font-size: 14px;
          white-space: initial;
        }
      }

      &.active {
        padding-bottom: 15px;
        height: auto;
      }
    }
  }

  .mobile-navbar {
    overflow-y: auto;
  }

  .contacts,
  .socials {
    @mixin mob-lg {
      text-align: center;
      margin: 0 auto;
    }
  }

  .contacts {
    margin: 30px 0;
  }

  @mixin mob-lg {
    width: 100%;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(100%);
  }

  0% {
    transform: translateX(0);
  }
}

/* end of ONLY Mobile HEADER */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
  .header {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .navbar {
      justify-content: space-between;
    }

    .navbar-nav {
      display: none;
    }
  }

  .mobile-header {
    display: block;
  }
}

/* end of Common desktop header Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  position: relative;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 20px;
  display: flex;

  span {
    background-color: var(--white);
    position: absolute;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    width: 100%;
    height: 3px;
    transition-duration: 500ms;

    &:nth-child(1) {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      bottom: 0;
      left: 0;
    }
  }

  @mixin transition-all;

  &.active {
    span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 13px;
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
        top: 13px;
      }
    }
  }
}

.socials {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;

  a {
    color: var(--white);
    font-size: 17px;
    transition: all 0.3s ease-in-out;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.contacts {
  display: flex;
  flex-direction: column;

  &__link {
    color: var(--white);
  }

  a:hover {
    text-decoration: underline;
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  @mixin transition-all;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: default;
  }
}

.socials-dropdown {
  position: absolute;
  background: var(--white);
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
  top: 75%;
  right: calc(0% - 15px);
  padding: 10px;
  display: none;

  justify-content: center;
  align-items: center;

  a {
    font-size: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 5px;
    width: 35px;
    text-align: center;

    transition: setTransition(250ms, color, background);

    i {
      transition: setTransition(150ms, color, background);
    }

    &:hover {
      background: var(--black);
      color: var(--white);
    }
  }
}

.socials-dropdown__wrap {
  position: relative;
  cursor: default;
  padding: 34px 0;

  &:hover {
    .socials-dropdown {
      display: flex;
    }
  }

  @mixin media 1300 {
    display: none;
  }
}

.socials-dropdown__title {
  position: relative;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &::after {
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    content: "\e906";
    font-family: "icomoon";
    font-size: ac(10px, 8px);
    transition: transform 0.3s ease;
  }
}

/* end of HEADER COMPONENTS */
