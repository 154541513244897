.about-us-first-section {
}

.countries {
  padding-top: 56px;

  .cont {
    position: relative;
  }
}

.countries__text {
  max-width: 1038px;

  margin: 0 auto 50px;

  color: var(--dark-grey);

  text-align: center;

  p {
    font-size: 16px;
    font-weight: 300;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.countries__map-title {
  margin-bottom: 35px;

  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.countries__tab-list {
  position: relative;

  display: flex;
  justify-content: center;

  width: fit-content;

  margin: 0 auto;

  @mixin media 769 {
    width: unset;
    display: none;
  }
}

.countries__tab {
  transition: color 350ms ease;

  &:not(:last-of-type) {
    margin-right: ac(100px, 40px);
  }

  &.active {
    color: var(--white);

    font-weight: 600;
  }

  @mixin media 769 {
    display: flex;
    justify-content: center;
  }
}

.countries__tab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;

  padding: 0 20px;

  transition: color 250ms ease;

  text-align: center;

  @mixin media 769 {
    padding: 0 5px;

    font-size: 18px;
  }
}

.countries__tabs-left-bg,
.countries__tabs-right-bg {
  position: absolute;
  top: -1px;
  z-index: -1;

  width: 100vw;
  height: 100%;

  background-color: var(--white);

  transition: left ease 350ms;
}

.countries__tabs-left-bg {
  transform: translateX(-100%);
}

.countries__tabs-left-bg-mob,
.countries__tabs-right-bg-mob {
  position: absolute;
  bottom: 1px;
  z-index: -1;

  width: 100vw;
  height: 56px;

  background-color: var(--white);

  display: none;

  @mixin media 769 {
    display: block;
  }
}

.countries__tabs-left-bg-mob {
  right: 50%;
  transform: translateX(-80px);
}

.countries__tabs-right-bg-mob {
  /* display: none; */

  left: 50%;
  transform: translateX(80px);
}

.countries__tab-list-mob {
  position: relative;
  display: none;
  overflow: hidden;

  /* width: 160px; */

  margin: 0 auto;

  @mixin media 769 {
    display: block;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -1px;
    z-index: 2;

    width: 97px;
    height: 100%;
  }

  &::before {
    left: 0;

    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 15%,
      rgba(255, 255, 255, 0)
    );
  }

  &::after {
    right: 0;

    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 1) 15%,
      rgba(255, 255, 255, 0)
    );
  }

  .swiper-slide {
    width: 160px;
  }

  .swiper-slide-active {
    .countries__tab-btn {
      color: var(--white);
    }
  }
}

.countries__tab-list-mob-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 30px;

  color: var(--black);

  font-size: 14px;

  &.next {
    right: 0;
    z-index: 3;
  }

  &.prev {
    left: 0;
  }
}

.countries__tab-mob {
  display: flex;
  justify-content: center;
}

.countries__tab-content-swiper {
  .swiper-slide {
    &::before {
      content: "";
      position: absolute;
      top: 0;

      width: 100%;
      height: 204px;

      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    }
  }
}

.countries__tab-content-wrap {
  position: relative;
  z-index: -3;

  margin-top: -56px;
}

.countries__tab-content {
  max-width: 1038px;
  width: 91.8%;

  margin: 0 auto;
  padding-top: 96px;
  padding-bottom: 54px;

  @mixin media 769 {
    width: 89%;
  }
}

.countries__tab-content-img {
  position: absolute;

  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -2;
}

.countries__tab-map-block {
  position: relative;
  overflow: hidden;

  margin-bottom: 8px;
  padding: 32px 0;

  background-color: var(--white-overlay);

  @mixin media 769 {
    margin-bottom: 16px;
    padding: 61px 0 16px;
  }
}

.countries__tab-title {
  position: absolute;
  top: 24px;
  left: 56px;

  font-weight: 600;
  text-transform: uppercase;

  &:before {
    right: 0;
  }

  @mixin media 769 {
    top: 16px;
    left: 16px;
  }
}

.countries__tab-map {
  width: 87.8%;

  margin: 0 auto;
}

.countries__tab-info-wrap {
  display: flex;

  max-height: 337px;

  @mixin media 769 {
    flex-direction: column;

    max-height: unset;
  }

  .countries__tab-county-list {
    width: 30.2%;
    max-height: 340px;

    margin-right: 8px;
    padding: 32px;

    background-color: var(--white-overlay);

    @mixin media 769 {
      width: 100%;

      margin-right: 0;
      margin-bottom: 16px;
      padding: 16px 16px 11px;

      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &::before {
          display: none;
        }
      }
    }

    li {
      list-style: none !important;
      font-weight: 300;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      @mixin media 769 {
        font-size: 14px;
      }
    }
  }
}

.countries__tab-text-block {
  display: flex;
  flex-direction: column;

  width: 69.1%;

  padding: 32px;

  background-color: var(--white-overlay);

  @mixin media 769 {
    width: 100%;
    padding: 16px;
  }

  p {
    font-family: var(--font-second);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.62;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .simplebar-offset {
    padding-right: 14px;
  }
}

.countries__tab-text {
  flex: 1 1 100%;

  max-height: 227px;

  @mixin media 769 {
    max-height: 359px;
  }
}

.countries__tab-text-link {
  margin-top: auto;

  font-weight: 600;
  text-decoration: underline;
  text-transform: uppercase;

  /* @mixin media 769 {
    display: none;
  } */
}

.specialisms {
  padding-top: 48px;
  padding-bottom: 40px;
}

.specialisms__section-title {
  margin-bottom: 32px;
}

.specialisms-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  grid-gap: 32px;

  @mixin media 1025 {
    grid-template-columns: repeat(2, 1fr);
  }

  @mixin media 601 {
    grid-template-columns: 1fr;
  }
}

.specialisms-list__item {
  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 396px;

  padding: 0 50px;

  cursor: pointer;

  &:hover {
    .specialisms-list__item-img {
      transform: scale(1.1);
    }

    &::before {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  @mixin media 551 {
    height: 343px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    background-color: var(--black-overlay);

    transition: background-color ease 250ms;
  }
}

.specialisms-list__item-img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  transition: transform ease 250ms;
}

.specialisms-list__item-title {
  z-index: 1;

  color: var(--white);

  font-size: 32px;
  font-weight: 300;
  text-align: center;
}

.corporate {
  padding: ac(64px, 40px) 0 ac(94px, 52px);

  background-color: var(--light-grey);
}

.corporate__section-title {
  margin-bottom: 38px;
}

.corporate__text-wrap {
  max-width: 1036px;
  margin: 0 auto;
}

.corporate__text-video-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @mixin media 901 {
    flex-direction: column;
  }
}

.corporate__text {
  /* width: 38.2%; */
  max-width: 369px;
  margin-right: 20px;

  @mixin media 901 {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 68px;
  }
}

.corporate__text-title {
  margin-bottom: 24px;

  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
}

.corporate__text-subtitle {
  margin-bottom: 24px;

  font-family: var(--font-second);
  font-size: 18px;
  font-weight: 500;
}

.corporate__text-content {
  p {
    color: var(--dark-grey);

    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
  }
}

.corporate__video {
  width: 100%;
  max-width: 503px;

  @mixin media 901 {
    margin: 0 auto 32px;
  }
}

.corporate__popup-btn {
  margin-top: 20px;
}

.our-values {
  padding-top: 48px;
}

.our-values__section-title {
  margin-bottom: 32px;
}

.values-list {
  display: flex;
  flex-wrap: wrap;

  max-width: 1920px;

  margin: 0 auto;

  @mixin media 501 {
    flex-direction: column;
    flex-wrap: nowrap;

    display: none;
  }
}

.values-list-swiper {
  display: none;

  @mixin media 501 {
    display: block;
  }

  .swiper-slide {
    height: auto;
  }
}

.values-list-swiper__nav {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 15px;
}

.values-list-swiper__btn {
  font-size: 20px;

  &:not(:last-child) {
    margin-right: 15px;
  }

  transition: setTransition(transform);

  &.swiper-button-disabled {
    opacity: 0.3;

    cursor: not-allowed;
  }

  &:not(.swiper-button-disabled):hover {
    transform: scale(1.2);
  }
}

.values-list__item {
  --card-width: 227px;

  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: var(--card-width);

  height: 350px;

  padding: 30px 15px;

  color: var(--white);

  cursor: pointer;

  transition: flex ease 250ms, max-height ease 250ms;

  @mixin media 501 {
    /* max-height: 77px; */
    padding: 0;
    transition: flex ease 600ms, max-height ease 600ms;
  }

  @mixin min-media 1550 {
    height: 450px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    background-color: rgba(0, 0, 0, 0.4);

    transition: background-color ease 250ms;

    @mixin media 501 {
      transition: background-color ease 600ms;
    }
  }

  &::after {
    content: "\e905";
    position: absolute;
    top: 33px;
    right: 15px;

    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;

    transition: transform ease 250ms;

    @mixin media 501 {
      transition: transform ease 600ms;
    }
  }

  &.active {
    @mixin media 501 {
      max-height: 1000px;
    }

    &::before {
      background-color: var(--black);
    }

    &::after {
      transform: rotate(90deg);
    }

    .values-list__item-text {
      overflow: auto;

      max-height: 100%;

      /* padding-bottom: 24px; */

      opacity: 1;
      visibility: visible;

      transition: opacity 250ms ease 250ms, visibility 250ms ease 250ms;

      @mixin media 501 {
        transition: opacity 600ms ease, visibility 600ms ease;
      }

      .simplebar-offset {
        padding-right: 14px;
      }

      .simplebar-scrollbar {
        &::before {
          background-color: var(--white);
        }
      }
    }
  }
}

.values-list__item-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.values-list__item-title {
  max-width: 150px;
  text-transform: uppercase;

  margin-bottom: 15px;

  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;

  @mixin media 501 {
    display: flex;
    align-items: center;

    max-width: 100%;
    min-height: 77px;

    padding: 0 15px;
    margin-bottom: 0;
  }
}

.values-list__item-text {
  width: 100%;

  font-size: 16px;
  font-weight: 300;

  visibility: hidden;
  opacity: 0;

  transition: opacity 0ms ease, visibility 0ms ease;

  @mixin media 501 {
    padding: 0 15px;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.diveristy {
  padding-top: 56px;
}

.diveristy__section-title {
  margin-bottom: 32px;
}

.diveristy__content-wrap {
  display: flex;

  justify-content: space-between;

  @mixin media 769 {
    flex-direction: column;
  }
}

.diveristy__quote {
  max-width: 325px;

  padding-top: 39px;
  margin-right: 20px;

  font-size: 24px;
  font-weight: 600;

  @mixin media 769 {
    max-width: 100%;

    padding-top: 0;
    margin-right: 0;
    margin-bottom: 16px;
  }

  @mixin min-media 1550 {
    margin-right: 60px;
  }
}

.diveristy__text {
  max-width: 824px;

  @mixin min-media 1550 {
    max-width: 1150px;
  }

  p {
    color: var(--dark-grey);

    font-family: var(--font-second);
    font-size: 16px;
    font-weight: 300;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.diveristy__bottom-img {
  width: 100%;
  min-width: 546px;
}
